<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBRow>
        <MDBCol class="mb-2 text-start" md="2">
          <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="language.selected"></MDBSelect>
        </MDBCol>
        <MDBCol class="mb-2 text-end">
          <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
          <MDBBtn color="info" @click="add_dialog = true">{{ $t("HOMEMANAGE.ADD-ITEM") }}</MDBBtn>  
        </MDBCol>
      </MDBRow>
      <MDBCardText>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
          :pagination="false"
        >
          <template v-slot:lang="{ item }">
            {{ $t("LANG." + item.lang) }}
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <template v-slot:status="{ item }">
            <div class="form-check form-switch cursor-pointer" @click="StatusAction(item.uuid,item.status)">
              <input class="form-check-input" type="checkbox" :id="item.uuid" :checked="item.status == 1" disabled>
            </div>
          </template>
          <template v-slot:members="{ item }">
            {{ item.members.length }}
          </template>
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('HOMEMANAGE.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('HOMEMANAGE.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
        </DataTable>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
  <HomeForm v-model="add_dialog" action="insert"></HomeForm>
  <HomeForm v-model="edit_dialog" action="update" v-model:uuid="EditUUid"></HomeForm>
</template>

<style scope>
  .cursor-pointer {
    cursor: pointer;
  }
  .form-check-input:disabled {
    opacity: 1;
  }
</style>

<script>
import { MDBRow, MDBCol, MDBSelect, MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBIcon } from 'mdb-vue-ui-kit';
import Breadcrumbs from '@/components/Breadcrumbs';
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import PageLoading from '@/components/Elements/PageLoading';
import DataTable from '@/components/Plugin/DataTable';
import HomeForm from '@/components/Form/Home/HomeForm';

export default {
  name: "HOMEMANAGE",
  components: {
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
    PageLoading,
    MDBIcon,
    DataTable,
    HomeForm
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const EditUUid = ref("");
    const total = ref(0);
    const loading = ref(true);
    const language = reactive({
      items: i18nService.languages,
      selected: ""
    });
    const Images = reactive({});
    const Products = reactive([]);
    const desserts = ref([]);
    const options = reactive({
      page: 1,
      itemsPerPage: -1,
      sortBy: ["created_at"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const Common = CommonService;

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        class: "text-center",
        width:"50px"
      },
      { text: i18n.t("COMMON.LANGUAGE"), value: "lang",sortable: false ,class: "text-center"},
      { text: i18n.t("COMMON.NAME"), value: "title" ,class: "text-center"},
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" ,class: "text-center"},
      { text: i18n.t("COMMON.STATUS"), value: "status" ,width:"50px" ,class: "text-center"},
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,width:"50px",class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,width:"50px",class: "text-center"}
    ]);

    const SelectedLanguage = function() {
      language.items.map(item => {
        if(item.lang == i18nService.getActiveLanguage()){
          item.selected = true;
        }else{
          item.selected = false;
        }
      });
    };

    const GetImage = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Image",
          {
            params: {}
          }
        ).then(response => {
          if (response.status == 200) {
            let imgs = response.data.data;
            for (let index in imgs) {
              Images[imgs[index].id] = {
                id: imgs[index].id,
                name: imgs[index].filename,
                image: CommonService.ImageUrlHandle(imgs[index])
              };
            }
            resolve(response);
          }
        });
      });
    };

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/HomeSettings",
          {
            params: Object.assign(CommonService.SortHandleObject(options),{lang:language.selected})
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/HomeSettings/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    const StatusAction = function(uuid,status) {
      CommonService.StatusSwal(status).then(result => {
        if (result.isConfirmed) {
          ApiService.update("/Admin/v1/HomeStatus", uuid,{ status: +!status,lang:language.selected}).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    const GetProduct = function() {
      return new Promise(resolve => {
        ApiService.query(
          "/Admin/v1/Product",
          {
            params: {
              page: 1,
              limit: -1,
              sortBy: ["created_at"],
              sortDesc: [],
            }
          }
        ).then(response => {
          if (response.status == 200) {
            response.data.data.data.map(item => {
              Products.push(item);
            });
            resolve(response);
          }
        });
      });
    };

    SelectedLanguage();

    return {
      language,
      add_dialog,
      edit_dialog,
      PageLoading,
      EditUUid,
      Images,
      options,
      headers,
      desserts,
      total,
      loading,
      SelectedLanguage,
      GetImage,
      GetTable,
      Common,
      DeleteAction,
      GetProduct,
      Products,
      StatusAction
    }
  },
  provide () {
    return {
      GetTable: this.GetTable,
      Images: this.Images,
      Products: this.Products
    }
  },
  mounted() {
    Promise.all([
      this.GetImage(),
      this.GetProduct(),
      // this.$store.dispatch("ImageFolder")
    ]).then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
    
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
    "language.selected": function(){
      this.GetTable();
    }
  }
};
</script>
