<template>
    
    <MDBCol md="3" sm="12" class="mb-3">
        <MDBRow>
          <MDBCol>
            <span class="text-danger">{{ error.ico }}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <span class="text-danger">{{ error.content }}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol md="12" sm="12" class="mb-2">
            <img :src="input_url" class="home-icon">
            </MDBCol>
            <MDBCol md="12" sm="12">
            <MDBBtn type="button" color="primary" @click="link_model=true">{{ $t("COMMON.EDIT") }}</MDBBtn>
            </MDBCol>
        </MDBRow>
        <MDBModal tabindex="-1" v-model="link_model" staticBackdrop size="lg">
            <MDBModalHeader>
                <!-- <MDBModalTitle>{{ $t("COMMON.SETCAROUSEL") }}</MDBModalTitle> -->
            </MDBModalHeader>
            <MDBModalBody>
                <MDBContainer>
                    <MDBRow class="mb-3 d-flex justify-content-center">
                        <MDBCol md="4">
                            <template v-if="action == 'insert'">
                                <Slim v-model="input_ico" label="請上傳 64 x 64 尺寸的圖片" v-model:url="input_url" :width="LinkIco.width" :height="LinkIco.height"></Slim>
                            </template>
                            <template v-else>
                                <div  v-if="edit" class="edit-slim ">
                                    <img :src="input_url" class="mb-2">
                                    <MDBBtn color="primary" type="button" @click="EditLinkImage">{{ $t("COMMON.EDIT") }}</MDBBtn>
                                </div>
                                <Slim v-model="input_ico" label="請上傳 64 x 64 尺寸的圖片" v-model:url="input_url" :width="LinkIco.width" :height="LinkIco.height"  v-else></Slim>
                            </template>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Ckeditor5 label="" v-model="input_content"></Ckeditor5>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="primary" @click="SaveLink">{{ $t("COMMON.SAVE") }}</MDBBtn>
                    <MDBBtn @click="link_model = false">{{ $t("COMMON.CLOSE") }}</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
    </MDBCol>
</template>

<style scope>
  .image-modal {
    height: 85vh;
    overflow-y: auto;
  }
</style>

<script>
import {  MDBRow, MDBBtn, MDBCol,  MDBModalBody, MDBModalFooter, MDBModal, MDBModalHeader, MDBContainer   } from 'mdb-vue-ui-kit';
import { computed, ref, reactive } from "vue";
import Slim from '@/components/Plugin/Slim';
import Ckeditor5 from '@/components/Plugin/Ckeditor5';

export default {
  name: "HomeFormLink",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBModalBody,
    MDBModalFooter,
    MDBModal,
    MDBModalHeader,
    Slim,
    MDBContainer,
    Ckeditor5
  },
  props: {
    ico: {
        default: "",
    },
    content: {
        type:String,
        default: "",
    },
    url: {
        type:String,
        default: "",
    },
    action: {
        type:String,
        default: "insert",
    },
    error: {
        default: {
            ico: "",
            content: "",
        }
    }
  },
  setup(props, { emit }) {
    const input_ico = computed({
      get: () => props.ico,
      set: val => emit("update:ico", val)
    });

    const input_content = computed({
      get: () => props.content,
      set: val => emit("update:content", val)
    });

    const input_url = computed({
      get: () => props.url,
      set: val => emit("update:url", val)
    });

    const link_model = ref(false);
    const edit = ref(false);

    const LinkIco = reactive({
      width:64,
      height:64
    });

    const SaveLink = function() {
      link_model.value = false;
      edit.value = false;
    };

    const EditLinkImage = function() {
      edit.value = !edit.value;
      input_ico.value = "";
    }

    if(props.action !='insert') {
        edit.value = true;
    }

    return {
        input_ico,
        input_content,
        input_url,
        link_model,
        LinkIco,
        SaveLink,
        EditLinkImage,
        edit,
    };
  },
};
</script>