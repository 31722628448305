<template>
  <MDBModal
    tabindex="-1"
    v-model="Model"
    staticBackdrop
    size="lg"
  >
    <form @submit="CarouselSubmit">
      <MDBModalHeader>
        <MDBModalTitle id="exampleModalLabel">{{ $t("COMMON.SETCAROUSEL") }}</MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody class="g-3 home">
        <MDBRow class="mb-3">
          <MDBCol>
            <label class="form-label text-dark">{{ $t('COMMON.LINK') }}</label>
            <input type="text" class="form-control" name="link" :placeholder="$t('COMMON.LINK')" v-model="CarouselForm.link.value">
            <span class="text-danger">{{ linkError }}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol>
            <label class="form-label text-dark">{{ $t('COMMON.SORT') }}<span class="text-danger">*</span></label>
            <input type="number" class="form-control" name="sort" :placeholder="$t('COMMON.SORT')" v-model="CarouselForm.sort.value">
            <span class="text-danger">{{ sortError }}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <label class="form-label text-dark">{{ $t('COMMON.SELECTIMAGE') }}<span class="text-danger">*</span></label>
            <template v-if="action == 'insert'">
              <Slim v-model="CarouselForm.image.value" label="請上傳 1920 x 720 尺寸的圖片" v-model:url="CarouselForm.url.value" :width="CarouselImage.width" :height="CarouselImage.height"></Slim>
            </template>
            <template v-else>
              <div  v-if="!edit" class="edit-slim ">
                <img :src="CarouselForm.url.value" class="mb-2">
                <MDBBtn color="primary" type="button" @click="EditCarousel">{{ $t("COMMON.EDIT") }}</MDBBtn>
              </div>
              <Slim v-model="CarouselForm.image.value" label="請上傳 1920 x 720 尺寸的圖片" v-model:url="CarouselForm.url.value" :width="CarouselImage.width" :height="CarouselImage.height" v-else></Slim>
            </template>
            <span class="text-danger">{{ ImageError }}</span>
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="primary" type="submit">{{ $t("COMMON.SAVE") }}</MDBBtn>
        <MDBBtn @click="Close()">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </MDBModalFooter>
    </form>
  </MDBModal>
</template>

<style scope>
  .home .image-modal {
    height: 40vh;
    overflow-y: auto;
  }
  .btn-close {
    display: none;
  }
  .edit-slim img {
    width: 100%;
  }
</style>

<script>
import { MDBRow, MDBCol, MDBBtn, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from 'mdb-vue-ui-kit';
import { computed, ref, reactive } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import Slim from '@/components/Plugin/Slim';

export default {
  name: "HomeCarouselSelect",
  props: {
    carousel: {
      type: Array,
      default: function (){
        return [];
      }
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    EditItem: {
      type: [Number,String],
      default: ""
    },
    action: {
      type: String,
      default: "insert"
    }
  },
  components: {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    Slim
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val),
    });

    const HomeCarousel = computed({
      get: () => props.carousel,
      set: val => emit("update:carousel", val),
    });

    const i18n = useI18n();
    const edit = ref(false);
    const CarouselSchema = yup.object({
      image: yup.string().required().label(i18n.t("COMMON.IMAGE")),
      link: yup.string().nullable().label(i18n.t("COMMON.LINK")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
      url: yup.string().label(i18n.t("COMMON.SORT")),
    });

    const { handleSubmit, resetForm } = useForm({
      validationSchema: CarouselSchema,
    });

    const { value: image, errorMessage: ImageError } = useField('image');
    const { value: link, errorMessage: linkError } = useField('link');
    const { value: sort, errorMessage: sortError } = useField('sort');
    const { value: url, errorMessage: urlError } = useField('url');

    const carousel = HomeCarousel;

    const CarouselForm = {
      image,
      link,
      sort,
      url
    };

    const CarouselImage = reactive({
      width:1920,
      height:720
    })

    const CarouselSubmit = handleSubmit(values => {
      if(props.action == "update"){
        carousel.value[props.EditItem] = values
      }else{
        // carousel.value[values.sort] = values;
        carousel.value.push(values);
      }
      carousel.value.sort(function (a, b) {
        return a.sort - b.sort;
      });
      HomeCarousel.value = carousel.value;
      resetForm();
      Model.value = false;
      return false;
    });

    const SetCarousel = function() {
      let item = HomeCarousel.value[props.EditItem];
      CarouselForm.image.value = item.image;
      CarouselForm.sort.value = item.sort;
      CarouselForm.url.value = item.url;
      if(item.link != "undefined"){
        CarouselForm.link.value = item.link;
      }else{
        CarouselForm.link.value = "";
      }
    };

    const EditCarousel = function() {
      edit.value = true;
      CarouselForm.image.value = "";
    }

    const Close = function() {
      Model.value = false;
    }

    return {
      CarouselForm,
      CarouselSubmit,
      ImageError,
      linkError,
      sortError,
      urlError,
      HomeCarousel,
      Model,
      SetCarousel,
      Close,
      edit,
      EditCarousel,
      CarouselImage
    }
  },
  watch:{
    Model: function() {
      if(this.action == "update") {
        this.SetCarousel();
      }
    }
  }
};
</script>
