<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(action == 'insert')">{{ $t("PRODUCT-LIST.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(action == 'update')">{{ $t("PRODUCT-LIST.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3 mt-3 text-start" v-if="action == 'insert'">
          <MDBCol >
            <h5> {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" v-if="action == 'insert'">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow>
        <MDBRow class="mb-3 justify-content-start">
          <MDBCol >
            <h5> {{ $t("COMMON.GENERALSETTINGS") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="12">
            <div>
              <label class="form-label text-dark" :data-tag="`${action}-title`">{{ $t('COMMON.TITLE') }}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" :placeholder="$t('COMMON.TITLE')" v-model="Form.title.value">
              <span class="text-danger">{{ titleError }}</span>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="12">
            <div>
              <label class="form-label text-dark">{{ $t('COMMON.MAINTITLE') }}</label>
              <input type="text" class="form-control" :placeholder="$t('COMMON.MAINTITLE')" v-model="Form.product.main_title.value">
              <span class="text-danger">{{ main_titleError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12">
            <div>
              <label class="form-label text-dark">{{ $t('COMMON.SECONDTITLE') }}</label>
              <input type="text" class="form-control" :placeholder="$t('COMMON.SECONDTITLE')" v-model="Form.product.second_title.value">
              <span class="text-danger">{{ second_titleError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12">
            <div>
              <label class="form-label text-dark">{{ $t('COMMON.BUYNOW') }}</label>
              <input type="text" class="form-control" :placeholder="$t('COMMON.BUYNOW')" v-model="Form.product.buynow.value">
              <span class="text-danger">{{ buynowError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12">
            <div>
              <label class="form-label text-dark">{{ $t('COMMON.MOREINFO') }}</label>
              <input type="text" class="form-control" :placeholder="$t('COMMON.MOREINFO')" v-model="Form.product.moreinfo.value">
              <span class="text-danger">{{ moreinfoError }}</span>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3 justify-content-start">
          <MDBCol >
            <h5> {{ $t("COMMON.SETCAROUSEL") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol class="mb-3" md="12">
            <MDBBtn type="button" color="primary" @click="carousel_modal = true">{{ $t("COMMON.SETCAROUSEL") }}</MDBBtn>
          </MDBCol>
          <MDBCol class="mb-3">
            <swiper
              :slides-per-view="1"
              navigation
              :pagination="{'clickable': true}"
            >
              <swiper-slide v-for="(swiper, k) in Form.carousel.value" :key="k">
                <img :src="swiper.url">
                <div class="swiper-button mt-2">
                  <MDBBtn color="primary" type="button" @click="EditCarousel(k)">{{ $t("COMMON.EDIT") }}</MDBBtn>
                  <MDBBtn color="danger" type="button" @click="DeleteCarousel(k)">{{ $t("COMMON.DELETE") }}</MDBBtn>  
                </div>
              </swiper-slide>
            </swiper>
          </MDBCol>
          <HomeCarouselSelect v-model="carousel_modal" :carousel="Form.carousel.value" action="insert"></HomeCarouselSelect>
          <HomeCarouselSelect v-model="edit_carousel_modal" :carousel="Form.carousel.value" action="update" :EditItem="EditCarouselKey"></HomeCarouselSelect>
        </MDBRow>
        <!-- <DealerCarousel v-model:model="carousel_modal" v-model:carousel="Form.carousel.value"></DealerCarousel> -->
        <MDBRow class="mb-3 justify-content-start">
          <MDBCol >
            <h5> {{ $t("COMMON.RELATEDLINK") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <HomeFormLink :action="action" v-model:ico="link_0_ico" v-model:content="link_0_content" v-model:url="Form.link.value[0].url" :error="{
            ico: link_0_icoError,
            content: link_0_contentError,
          }"></HomeFormLink>
          <HomeFormLink :action="action" v-model:ico="link_1_ico" v-model:content="link_1_content" v-model:url="Form.link.value[1].url" :error="{
            ico: link_1_icoError,
            content: link_1_contentError,
          }"></HomeFormLink>
          <HomeFormLink :action="action" v-model:ico="link_2_ico" v-model:content="link_2_content" v-model:url="Form.link.value[2].url" :error="{
            ico: link_2_icoError,
            content: link_2_contentError,
          }"></HomeFormLink>
          <HomeFormLink :action="action" v-model:ico="link_3_ico" v-model:content="link_3_content" v-model:url="Form.link.value[3].url" :error="{
            ico: link_3_icoError,
            content: link_3_contentError,
          }"></HomeFormLink>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <span class="text-danger">{{ linkError }}</span>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3 justify-content-start">
          <MDBCol >
            <h5> 設定商品</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol class="mb-3" md="12">
            <MDBBtn type="button" color="primary" @click="product_modal = true">加入商品</MDBBtn>
          </MDBCol>
          <MDBCol md="6">
            <MDBTable
              class="table datatable-table"
              responsive
            >
              <thead>
                <tr>
                  <th></th>
                  <th>{{ $t("COMMON.PRODUCTNAME") }}</th>
                  <th>{{ $t("COMMON.PRODUCTNUMBER") }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(row ,k) in Products" :key="k">
                  <tr v-if="Form.products.value.indexOf(row.uuid)>=0">
                    <td scope="row"><input type="checkbox" v-model="Form.products.value" :value="row.uuid"></td>
                    <td scope="row">{{row.name}}</td>
                    <td scope="row">{{row.product_number}}</td>
                  </tr> 
                </template>
              </tbody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
        <MDBModal
          tabindex="-1"
          v-model="product_modal"
          size="lg"
        >
          <MDBModalHeader>
            <MDBModalTitle>加入商品</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow class="mb-3">
                <MDBCol md="4">
                  <MDBInput  v-model="where.amy" />
                </MDBCol>
                <MDBCol md="4">
                  <MDBInput  v-model="where.jason" />
                </MDBCol>
              </MDBRow>
              <div style="height:65vh;overflow-y: auto;overflow-x: hidden;">
                <MDBTable
                  class="table datatable-table"
                  responsive
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>{{ $t("COMMON.PRODUCTNAME") }}</th>
                      <th>{{ $t("COMMON.PRODUCTNUMBER") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="tmp_commodity.length > 0">
                      <tr v-for="(row ,k) in tmp_commodity" :key="k" >
                        <td scope="row"><input type="checkbox" v-model="Form.products.value" :value="row.uuid"></td>
                        <td scope="row">{{row.name}}</td>
                        <td scope="row">{{row.product_number}}</td>
                      </tr> 
                    </template>
                    <tr v-else>
                      <td colspan="9" class="text-center">無資料</td>
                    </tr>
                  </tbody>
                </MDBTable>
              </div>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn @click="product_modal = false">{{ $t("COMMON.CLOSE") }}</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .image-modal {
    height: 85vh;
    overflow-y: auto;
  }
</style>

<script>
import {  MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBModalBody, MDBModalFooter, MDBModal, MDBModalHeader, MDBContainer, MDBModalTitle, MDBInput,MDBTable   } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref, reactive } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import HomeCarouselSelect from '@/components/Elements/HomeCarouselSelect';
import HomeFormLink from '@/components/Form/Home/HomeFormLink';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, {
  Navigation
} from 'swiper/core';

SwiperCore.use([Navigation]);

export default {
  name: "HomeForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBModalBody,
    MDBModalFooter,
    MDBModal,
    Swiper,
    SwiperSlide,
    MDBModalHeader,
    HomeCarouselSelect,
    HomeFormLink,
    MDBContainer,
    MDBModalTitle,
    MDBInput,
    MDBTable 
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    dealer_id: {
      type: [String,Number],
      default: 0
    }
  },
  inject: ["Images","Products"],
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });

    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const Common = CommonService;
    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const languages = i18nService.languages;
    const language = i18nService.getActiveLanguage();
    const carousel_modal = ref(false);
    const product_modal = ref(false);
    const SubmitLoading = ref(false);
    const edit_info = ref(false);
    const edit_carousel_modal = ref(false);
    const EditCarouselKey = ref("");
    const swipers = ref([]);
    const EditItem = ref("");
    const edit = ref(false);
    const url = ref("");
    const tmp_commodity = ref([]);
    const commoditys = ref([]);
    const where = reactive({
        amy: "",
        jason: "",
      })
    const LinkForm = reactive({
      ico:"",
      content:""
    });
    const LinkIco = reactive({
      width:64,
      height:64
    })

    const basicSchema = {
      dealer_id: yup.string().required(),
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      title: yup.string().required().label(i18n.t("COMMON.TITLE")),
      carousel: yup.array().label(i18n.t("COMMON.SETCAROUSEL")),
      products: yup.array().label(i18n.t("COMMON.SETCAROUSEL")),
      link: yup.array().of(yup.object().shape({
        ico: yup.string().required().label("標誌"),
        content: yup.string().required().label("名稱"),
      })).length(4).label(i18n.t("COMMON.LINK")),
      main_title: yup.string().label(i18n.t("COMMON.MAINTITLE")),
      second_title: yup.string().label(i18n.t("COMMON.SECONDTITLE")),
      buynow: yup.string().label(i18n.t("COMMON.BUYNOW")),
      moreinfo: yup.string().label(i18n.t("COMMON.MOREINFO")),
    };

    let ExtendSchema = {};
    
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm, errors, isSubmitting } = useForm({
      validationSchema: FormSchema,
      initialValues : {
        carousel: [],
        products: [],
        lang: i18nService.getActiveLanguage(),
        dealer_id:props.dealer_id,
        link: [
          {
            ico: "",
            content:"",
            url: ""
          },
          {
            ico: "",
            content:"",
            url: ""
          },
          {
            ico: "",
            content:"",
            url: ""
          },
          {
            ico: "",
            content:"",
            url: ""
          },
        ]
      }
    });

    const { value: dealer_id, errorMessage: dealer_idError } = useField('dealer_id');
    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: title, errorMessage: titleError } = useField('title');
    const { value: link, errorMessage: linkError } = useField('link');
    const { value: carousel, errorMessage: carouselError } = useField('carousel');
    const { value: products, errorMessage: productsError } = useField('products');
    const { value: main_title, errorMessage: main_titleError } = useField('main_title');
    const { value: second_title, errorMessage: second_titleError } = useField('second_title');
    const { value: buynow, errorMessage: buynowError } = useField('buynow');
    const { value: moreinfo, errorMessage: moreinfoError } = useField('moreinfo');

    const { value: link_0_ico, errorMessage: link_0_icoError } = useField('link[0].ico');
    const { value: link_0_content, errorMessage: link_0_contentError } = useField('link[0].content');

    const { value: link_1_ico, errorMessage: link_1_icoError } = useField('link[1].ico');
    const { value: link_1_content, errorMessage: link_1_contentError } = useField('link[1].content');

    const { value: link_2_ico, errorMessage: link_2_icoError } = useField('link[2].ico');
    const { value: link_2_content, errorMessage: link_2_contentError } = useField('link[2].content');

    const { value: link_3_ico, errorMessage: link_3_icoError } = useField('link[3].ico');
    const { value: link_3_content, errorMessage: link_3_contentError } = useField('link[3].content');

    let Submit;
    if(props.action == "insert") {
      Submit = handleSubmit(values => {
        values.product = {
          main_title: values.main_title,
          second_title: values.second_title,
          buynow: values.buynow,
          moreinfo: values.moreinfo,
        };
        let form = CommonService.MakeForm(values);
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/HomeSettings", form).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        values.product = {
          main_title: values.main_title,
          second_title: values.second_title,
          buynow: values.buynow,
          moreinfo: values.moreinfo,
        };
        let form = CommonService.MakeForm(values);
        form.append('_method', 'PUT');
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/HomeSettings/" + props.uuid,form).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }

    const Form = {
      dealer_id,
      lang,
      title,
      carousel,
      link,
      products,
      product: {
        main_title,
        second_title,
        buynow,
        moreinfo
      },
    };

    const EditCarousel = function(index) {
      EditCarouselKey.value = index;
      edit_carousel_modal.value = true;
    };

    const DeleteCarousel = function(index) {
      Form.carousel.value = Form.carousel.value.filter(function(item,key) {
        return key !== index;
      })
    };

    const FileHandle = function(event) {
      if (event) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        let tmp = this;
        reader.onload = function() {
          tmp.LinkForm.ico = this.result;
        };
      }
    };

    const AddProduct = function(uuid) {
      if(Form.products.value.indexOf(uuid) < 0) {
        Form.products.value.push(uuid);
      }
    };

    const DeleteProduct = function(uuid) {
      if(Form.products.value.indexOf(uuid) >= 0) {
        Form.products.value.splice(Form.products.value.indexOf(uuid),1);
      }
    };

    const Close = function() {
      resetForm();
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Model,
      EditItem,
      carousel_modal,
      edit_carousel_modal,
      product_modal,
      EditCarouselKey,
      SubmitLoading,
      swipers,
      edit_info,
      Form,
      LinkForm,
      langError,
      titleError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      language,
      Close,
      carouselError,
      productsError,
      linkError,
      dealer_idError,
      main_titleError,
      second_titleError,
      buynowError,
      moreinfoError,
      EditCarousel,
      DeleteCarousel,
      FileHandle,
      url,
      LinkIco,
      edit,
      Common,
      AddProduct,
      DeleteProduct,
      where,
      tmp_commodity,
      commoditys,

      link_0_ico,
      link_0_content,

      link_1_ico,
      link_1_content,

      link_2_ico,
      link_2_content,

      link_3_ico,
      link_3_content,

      link_0_icoError,
      link_0_contentError,

      link_1_icoError,
      link_1_contentError,

      link_2_icoError,
      link_2_contentError,

      link_3_icoError,
      link_3_contentError,

      errors,
      isSubmitting ,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    },
    "where.amy": function() {
      this.commodityFilter();
    },
    "where.jason": function() {
      this.commodityFilter();
    },
    isSubmitting : function() {
      let fields = Object.keys(this.errors);
      if(fields.length > 0) {
        var element = document.querySelector(`[data-tag="${this.action}-${fields[0]}"]`);
        if(element) {
          var top = element.offsetTop;
          document.querySelector('main').scrollTo(0,top);
        }
      }
    }
  },
  methods: {
    commodityFilter() {
      var amy = new RegExp(this.where.amy);
      var jason = new RegExp(this.where.jason);
      this.tmp_commodity = this.Products.filter((item) => {
        let amy_result = true;
        let jason_result = true;
        if(this.where.amy) {
          amy_result = (item.name.match(amy) || item.product_number.match(amy));
        }
        if(this.where.jason) {
          jason_result = (item.name.match(jason) || item.product_number.match(jason));
        }
        return amy_result && jason_result ;
      });
    },
    GetDetail() {
      this.resetForm();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/HomeSettings", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "carousel":
                      response.data.data.carousel.map(item => {
                        this.Form.carousel.value.push({image:item.id,sort:item.pivot.order,link:item.pivot.link,url:item.url});
                      });
                    break;
                    case "product":
                      for(let key in response.data.data.product){
                        if(response.data.data.product[key]) {
                          this.Form.product[key].value = response.data.data.product[key];
                        }
                      }
                    break;
                    case "products":
                      this.Form.products.value = response.data.data.products.map(item => item.uuid);
                    break;
                    case "link":
                      for(let k in response.data.data.link){
                        this.Form.link.value[k] = response.data.data.link[k];
                        this.Form.link.value[k].url = this.Form.link.value[k].ico;
                      }
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  },
  mounted: function() {
    this.tmp_commodity = this.Products;
  }
};
</script>